import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { COLLECTIONS } from '@models/collections';
import { Id } from '@models/common';
import { LicenseUserRate } from '@models/license';
import { CompetitionType } from '@models/masters';
import { PublicInfoUser, User, UserType } from '@models/user';
import { Optional } from '@models/utils';
import * as Sentry from '@sentry/angular-ivy';
import { FirestoreService } from '@services/firestore.service';
import { Observable, catchError, first, firstValueFrom, map, of, shareReplay, switchMap, takeUntil, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { ClubsService } from './club.service';
import { SeasonManagerService } from './season-manager.service';
import { TeamsService } from './teams.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private COLLECTION = COLLECTIONS.USERS;

  public uid$: Observable<Id> = this.auth.uid$;

  public user$: Observable<User> = this.auth.uid$.pipe(
    switchMap((uid) => this.firestore.getDocument<User>(this.COLLECTION, uid)),
    tap((user) => {
      Sentry.setUser({ email: user.email, id: user.id });
      Sentry.setContext('role', { types: user.types });
    }),
    shareReplay({ refCount: true }),
    takeUntil(this.auth.onLogout),
  );

  public myProfileWithLicenses$: Observable<{ user: User; licenses: LicenseUserRate[] }> = this.user$.pipe(
    switchMap((user) => this.getUserLicenses(user.id).pipe(map((licenses) => ({ user, licenses })))),
  );

  public roles$ = this.user$.pipe(map((u) => u.types || []));

  public isAdmin$: Observable<boolean> = this.roles$.pipe(map((roles) => roles.indexOf(UserType.ADMIN) > -1));
  public isRefereeCoordinator$: Observable<boolean> = this.roles$.pipe(
    map((roles) => roles.indexOf(UserType.REFEREE_COORDINATOR) > -1),
  );

  public isManager$ = this.user$.pipe(
    switchMap((user) => {
      if (!user || !user.clubId) {
        return of(false);
      } else {
        return this.clubService.get(user.clubId).pipe(
          map((club) => club.managerId === user.id),
          catchError(() => of(false)),
        );
      }
    }),
    shareReplay({ refCount: true }),
    takeUntil(this.auth.onLogout),
  );

  public isSchoolManager$ = this.user$.pipe(
    switchMap((user) => {
      if (!user || !user.clubId) {
        return of(false);
      } else {
        return this.clubService.get(user.clubId).pipe(
          map((club) => club.schoolManagerId === user.id),
          catchError(() => of(false)),
        );
      }
    }),
    shareReplay({ refCount: true }),
    takeUntil(this.auth.onLogout),
  );

  public isDelegateOf3aCAFF$ = this.user$.pipe(
    switchMap((user) => {
      if (!user || !user.clubId) {
        return of(false);
      } else {
        return this.teamsService.getByClub(user.clubId).pipe(
          map((teamsByClub) =>
            teamsByClub.some(
              (team) =>
                team.season?.scope === 'CAFF' &&
                team.season.type === CompetitionType.LEAGUE &&
                team.season?.level >= 4 &&
                team.delegatesIds?.includes(user.id),
            ),
          ),
          catchError(() => of(false)),
        );
      }
    }),
    shareReplay({ refCount: true }),
    takeUntil(this.auth.onLogout),
  );

  constructor(
    private firestore: FirestoreService,
    private auth: AuthService,
    private seasonManager: SeasonManagerService,
    private functions: Functions,
    private clubService: ClubsService,
    private teamsService: TeamsService,
  ) {}

  get(id: string) {
    return this.firestore.getDocument<User>(this.COLLECTION, id);
  }
  createOrUpdate(user: Optional<User, 'id'>) {
    user.document = user.document.toUpperCase();
    return user.id ? this.update(user.id, user) : this.createUser(user);
  }

  private createUser(user: Omit<User, 'id'>) {
    return this.firestore.create(this.COLLECTION, user);
  }

  public update(id: string, user: Partial<User>) {
    return this.firestore.update(this.COLLECTION, {
      id,
      ...user,
    });
  }

  updateUserToken(fcmToken: string): Promise<void> {
    return firstValueFrom(
      this.uid$.pipe(
        first(),
        switchMap((uid) => this.update(uid, { fcmToken })),
      ),
    );
  }

  getUserLicenses(userId: string) {
    console.log(userId);
    return this.seasonManager.season$.pipe(
      tap((seasonYear) => console.log('Season Year:', seasonYear)), // Verifica el valor
      switchMap((seasonYear) =>
        this.firestore.listDocuments<LicenseUserRate>(
          [COLLECTIONS.USERS, userId, COLLECTIONS.USERS_LICENSES].join('/'),
          where('seasonYear', '==', seasonYear),
        ),
      ),
      tap((licenses) => console.log('Licenses:', licenses)), // Verifica si hay resultados
      shareReplay(1),
    );
  }

  getPlayerPublicInfo(userId: string) {
    const callable = httpsCallable<{ userId: string }, PublicInfoUser>(this.functions, 'getPlayerPublicInfo');
    return callable({ userId });
  }
}
